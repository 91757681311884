import './spacer.scss';

export default function Spacer(props) {
  const selection = [20, 50, 100]
  let className = ['space'];

  if (selection.includes(props.space)) {
    className.push(props.space);
  }

  return <div className={`spacer ${className.join('__')}`} />;
}

