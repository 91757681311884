import { Component } from 'react';
import { get } from 'superagent';

import './blogPost.scss';

import { isText } from '../../Utils/validators';

import Image from '../../Atoms/Image/image';
import Spacer from '../../Atoms/Spacer/spacer';
import Typography from '../../Atoms/Typography/typography';
import TokenModal from '../../Molecules/TokenModal/tokenModal';
import HeaderImage from '../../Molecules/HeaderImage/headerImage';
import LoadingModal from '../../Molecules/LoadingModal/loadingModal';
import BlogCateories from '../../Molecules/BlogCategories/blogCategories';

export default class BlogPost extends Component {
  state = {
    blogPost: {},
    loading: true,
    error: false,
    mousePositionX: 0, 
    mousePositionY: 0,
  };

  _onMouseMove(e) {
    this.setState({
      mousePositionX: e.screenX,
      mousePositionY: e.screenY
    });
  }

  componentDidMount() {
    this.init(
      this.props
    );
  }

  init(props) {
    const {
      REACT_APP_CMS_URL
    } = process.env;

    const jsonWebToken = localStorage.getItem('jsonWebToken');

    get(`${REACT_APP_CMS_URL}/blog-posts?slugs=${window.location.pathname.replace('/blog/', '')}`)
    .set('Authorization', `Bearer ${jsonWebToken}`)
    .end((err, res) => {
      if (err) {
        this.setState({
          error: err
        }, () => this.setState({ loading: false }));

        return;
      }

      let title = '';

      if (
        res.body[0].titles
        && isText(res.body[0].titles)
      ) {
        title = res.body[0].titles;
      }
      
      document.title = `DataModelers - Blog - ${title}`;

      console.log(window.outerWidth)
      console.log(window.outerHeight)

      // TODO: FOREACH THE BODY!!!!
      this.setState({
        blogPost: res.body[0],
        innerWindowWidth: window.innerWidth,
        innerWindowHeight: window.innerHeight
      }, () => this.setState({ loading: false }));
    });
  }

  render() {
    const {
      error,
      loading,
      blogPost,
      mousePositionX,
      mousePositionY,
      innerWindowWidth,
      innerWindowHeight
    } = this.state;
    let coverImage = '';
    const {
      REACT_APP_CMS_URL
    } = process.env;

    if(loading) {
      return <LoadingModal/>;
    }

    if(blogPost.covers) {
      coverImage = blogPost.covers.url;
    }

    console.log({
      mousePositionX, mousePositionY,innerWindowWidth,
      innerWindowHeight
    })

    let returnArray = [
      <HeaderImage positions={{innerWindowWidth, innerWindowHeight, mousePositionX, mousePositionY}} key={'headerImage'} image={`${REACT_APP_CMS_URL}${coverImage}`}/>,
      <Image key={'blogImage'} width='100%' src={`${REACT_APP_CMS_URL}${coverImage}`}/>,
      <Typography key={'blogTitle'} component='h1'>{blogPost.titles}</Typography>,
      <Typography key={'blogCategoryTitles'} component='h2'>Categories</Typography>,
      <BlogCateories key={'blogCategories'} categories={blogPost.categories}/>,
      <Spacer key={'blogSpacer'} space={50}/>,
      <Typography key={'blogText'} component='markdown'>{blogPost.body_texts}</Typography>
    ];

    if(error) {
      returnArray = [<TokenModal/>];
    }
    
    return (
      <>
        <div className='blogPostPage'onMouseMove={this._onMouseMove.bind(this)}>
          <div className='blogPostWrapper'>
            {returnArray}
          </div>
        </div>
        <Spacer space={200}/>
      </>
    );
  }
  
}
