import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Typography from '../../Atoms/Typography/typography';

export default function ServiceOfferBox(props) {
  const {
    serviceOffers
  } = props;

  const countServiceOffers = Object.keys(serviceOffers).length;
  const subtitleText = [];
  
  if (props.withGoToPageLink) {
    subtitleText.push(
      <div key='withGoToPageLink'>
        Click <Link to='/service-offers'>here</Link> to view {countServiceOffers === 1 ? 'it' : 'them'}.
      </div>
    )
  }

  if (props.withSeeBelowText) {
    subtitleText.push(
      <div key='withSeeBelowText'>
        Find {countServiceOffers === 1 ? 'it' : 'them'} below in the pending service offers section.
      </div>
    )
  }

  return (
    countServiceOffers > 0 ?
      <div className='serviceOfferWrapper'>
        <Typography component='caption'>
          You have currently <strong>{countServiceOffers}</strong> service {countServiceOffers === 1 ? 'offer' : 'offers'} pending.
          {subtitleText}
        </Typography>
      </div>
    : null
  )
};

ServiceOfferBox.propTypes = {
  serviceOffers: PropTypes.object.isRequired
};