import { Component } from 'react';
import { get } from 'superagent';

import './blog.scss';

import Spacer from '../../Atoms/Spacer/spacer';
import Typography from '../../Atoms/Typography/typography';
import TokenModal from '../../Molecules/TokenModal/tokenModal';
import LoadingModal from '../../Molecules/LoadingModal/loadingModal';
import BlogPostList from '../../Organisms/BlogPostList/blogPostList';
import UpcomingBlogPostsList from '../../Organisms/UpcomingBlogPostList/upcomingBlogPostList';

export default class Blog extends Component {
  state = {
    blogPosts: [],
    upcominBlogPosts: [],
    loading: true,
    error: false
  }

  componentDidMount() {
    document.title = "DataModelers - Blog";

    this.loadUpcomingBlogPosts();

    this.init(
      this.props
    );
  }

  loadUpcomingBlogPosts() {
    const {
      REACT_APP_CMS_URL
    } = process.env;

    get(`${REACT_APP_CMS_URL}/blog-posts-upcomings?_sort=priorities:DESC`)
    .end((err, res) => {
      if (err) {
        console.log(err.message)

        return;
      }

      this.setState({
        upcominBlogPosts: res.body
      });
    });
  }

  init(props) {
    const {
      REACT_APP_CMS_URL
    } = process.env;
    const jsonWebToken = localStorage.getItem('jsonWebToken');

    get(`${REACT_APP_CMS_URL}/blog-posts?_sort=id:DESC`)
    .set('Authorization', `Bearer ${jsonWebToken}`)
    .end((err, res) => {
      if (err) {
        this.setState({
          error: err
        }, () => this.setState({ loading: false }));

        return;
      }

      this.setState({
        blogPosts: res.body
      }, () => this.setState({ loading: false }));
    });
  }

  render() {
    const {
      error,
      loading,
      blogPosts,
      upcominBlogPosts
    } = this.state;

    let returnArray = [
      <Typography key={'blogPostsTitle'} component='h1'>Blog Posts</Typography>,
      <BlogPostList key={'blogPostList'} blogPosts={blogPosts}/>
    ];

    if(loading) {
      return <LoadingModal/>;
    }

    if(error) {
      returnArray = [<TokenModal key={'tokenModal'}/>];
    }

    return (
      <div className='blogPage'>
        <div className='blogWrapper'>
          <Typography component='h1'>Upcoming Blog Posts</Typography>
          <UpcomingBlogPostsList upcominBlogPosts={upcominBlogPosts}/>
          <Spacer space={20}/>
          {returnArray}
        </div>
        <Spacer space={100}/>
      </div>
    );
  } 
}
