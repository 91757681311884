import './imprint.scss';

import Logo from '../../Atoms/Logo/logo';
import Spacer from '../../Atoms/Spacer/spacer';
import Button from '../../Atoms/Button/button';
import MailToLink from '../../Atoms/MailToLink/mailToLink';
import Typography from '../../Atoms/Typography/typography';

function revokeToken() {
  localStorage.removeItem('jsonWebToken');
  window.location.href = '/';
}

export default function Imprint(props) {
  document.title = `DataModelers - Imprint`;

  const token = localStorage.getItem('jsonWebToken');

  return (
    <div className='imprintPage'>
      <div className='imprintWrapper'>
        <Typography component='h1'>Imprint</Typography>
        <Logo width='250px' height='250px'/>
        <Typography component='h2'>Contact</Typography>
        <Typography component='text'>
          <MailToLink
            subject='Hey'
            mailto='hello@datamodelers.de'
            body='I have a question, how can i reach you out?'
          >
            hello@datamodelers.de
          </MailToLink>
        </Typography>
        {token ? <Typography component='h2'>Your Token</Typography> : null }
        {token ? <Typography component='text'>{token}</Typography> : null }
        {token ? <div
          className='imprintButton'
          onClick={() => revokeToken()}
          >
            Revoke Token
          </div> : null
        }
      </div>
      <Spacer space={100}/>
    </div>
  );
}
