export default function transformServiceOffers(serviceOffers) {
  const returnServiceOffers = {};

  serviceOffers.map(serviceOfferLine => {
    if(!returnServiceOffers[serviceOfferLine.offer_numbers]) {
      returnServiceOffers[serviceOfferLine.offer_numbers] = {};
    }

    if(!returnServiceOffers[serviceOfferLine.offer_numbers][serviceOfferLine.offer_versions]) {
      returnServiceOffers[serviceOfferLine.offer_numbers][serviceOfferLine.offer_versions] = {
        head: {
          offer_numbers: serviceOfferLine.offer_numbers,
          offer_versions: serviceOfferLine.offer_versions,
          offer_status: serviceOfferLine.offer_status,
          offer_dates: serviceOfferLine.offer_dates,
          bill_to_company_names: serviceOfferLine.bill_to_company_names,
          bill_to_names: serviceOfferLine.bill_to_names,
          bill_to_contacts: serviceOfferLine.bill_to_contacts,
          bill_to_streets: serviceOfferLine.bill_to_streets,
          bill_to_postal_codes: serviceOfferLine.bill_to_postal_codes,
          bill_to_cities: serviceOfferLine.bill_to_cities,
          bill_to_countries: serviceOfferLine.bill_to_countries,

          bill_from_company_names: serviceOfferLine.bill_from_company_names,
          bill_from_names: serviceOfferLine.bill_from_names,
          bill_from_contacts: serviceOfferLine.bill_from_contacts,
          bill_from_streets: serviceOfferLine.bill_from_streets,
          bill_from_postal_codes: serviceOfferLine.bill_from_postal_codes,
          bill_from_cities: serviceOfferLine.bill_from_cities,
          bill_from_countries: serviceOfferLine.bill_from_countries,
        },
        lines: [

        ]
      };
    }

    returnServiceOffers[serviceOfferLine.offer_numbers][serviceOfferLine.offer_versions].lines.push({
      offer_line_ids: serviceOfferLine.offer_line_ids,
      service_names: serviceOfferLine.service_names,
      service_descriptions: serviceOfferLine.service_descriptions,
      quantities: serviceOfferLine.quantities,
      unit_names: serviceOfferLine.unit_names,
      amounts: serviceOfferLine.amounts,
      currency_symbols: serviceOfferLine.currency_symbols,
      currency_names: serviceOfferLine.currency_names,
      amount_totals: serviceOfferLine.quantities * serviceOfferLine.amounts
    });
  });

  return returnServiceOffers;
}