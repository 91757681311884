import PropTypes from 'prop-types';

export default function Image(props) {
  let src = null;
  let altText = 'image';
  let className = 'image';
  let width = '100%';
  let height = '100%';

  if(props.className) {
    className = props.className;
  }

  if(props.alt) {
    altText = props.alt;
  }

  if(props.width) {
    width = props.width;
  }

  if(props.height) {
    width = props.height;
  }

  if(props.src) {
    src = props.src;
  }

  return <img
    src={src}
    className={className}
    alt={altText}
    width={width}
    height={height}
  />;
}

Image.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  src: PropTypes.string.isRequired
};
