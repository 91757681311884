import PropTypes from 'prop-types';

import './cardShortText.scss';

import Typography from '../../Atoms/Typography/typography';

export default function CardShortText(props) {
  return (
    <Typography className='cardShortText' component='caption'>
      {props.text.slice(0, 400)}...
    </Typography>
  );
}

CardShortText.propTypes = {
  text: PropTypes.string.isRequired
};
