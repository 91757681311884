import { Component } from 'react';
import COLORS from './colors';

import './screenSaver.scss';

import Logo from '../../Atoms/Logo/logo';
import RotatingImage from '../../Molecules/RotatingImage/rotatingImage';

var timeout = 0;

export default class ScreenSaver extends Component {
  state = {
    translateX: 0,
    translateY: 0,
    size: {},
    speed: 7,
    direction: 'se',
    color: '#ff4000',
    rotationDirection: 'Right',
    debug: false,
    isShown: false,
    showAfter: 60000,
    debugPosition: 0
  }

  componentDidMount() {
    this.initDimensions();
    this.eventListener('scroll');
    this.eventListener('resize');
    this.eventListener('click');
    this.eventListener('keydown');
    this.eventListener('mousemove');

    setInterval(() => {
      this.move();
    }, this.state.speed);
  }

  eventListener(trigger) {
    const self = this;

    window.addEventListener(trigger, function () {
      self.setState({
        isShown: false,
      });
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        self.setState({
          isShown: true,
          translateX: 0,
          translateY: 0,
        });
        self.initDimensions();
      }, self.state.showAfter);
    }, true);
  }

  initDimensions() {
    const box = this.divRef;

    if (box) {
      const state = {
        boxHeight: box.offsetHeight,
        boxWidth: box.offsetWidth,
        boxTop: box.offsetTop,
        boxLeft: box.offsetLeft,
        xMin: -box.offsetLeft,
        yMin: -box.offsetTop,
        xMax: window.innerWidth - box.offsetLeft - box.offsetWidth,
        yMax: window.innerHeight - box.offsetTop - box.offsetHeight,
      };

      this.setState({
        size: state,
      });
    }
  }

  move() {
    const {
      translateX,
      translateY,
      direction
    } = this.state;
    let newTranslateX = translateX,
      newTranslateY = translateY;

    switch (direction) {
      case 'ne':
        newTranslateX = translateX + 1;
        newTranslateY = translateY - 1;
        break;
      case 'nw':
        newTranslateX = translateX - 1;
        newTranslateY = translateY - 1;
        break;
      case 'se':
        newTranslateX = translateX + 1;
        newTranslateY = translateY + 1;
        break;
      case 'sw':
        newTranslateX = translateX - 1;
        newTranslateY = translateY + 1;
        break;        
    }

    this.setLimits(newTranslateX, newTranslateY);
  }

  setLimits(translateX, translateY) {
    const {
      yMin,
      yMax,
      xMin,
      xMax
    } = this.state.size;
    const {
      rotationDirection,
      direction,
      color
    } = this.state;
    let newColor = color;
    let newDirection = direction;
    let position = 0;
    let newRotationDirection = rotationDirection;

    if (translateY <= yMin) {
      if (direction === 'nw') {
        newDirection = 'sw';
        newRotationDirection = 'Right';

        position = 1;
      } else if (direction === 'ne') {
        newDirection = 'se';
        newRotationDirection = 'Right';

        position = 2;
      }

      newColor = this.getRandomFromArray(COLORS);
    }

    if (translateY >= yMax) {
      if (direction === 'se') {
        newDirection = 'ne';
        newRotationDirection = 'Right';

        position = 3;
      } else if (direction === 'sw') {
        newDirection = 'nw';
        newRotationDirection = 'Left';

        position = 4;
      }

      newColor = this.getRandomFromArray(COLORS);
    }

    if (translateX <= xMin) {
      if (direction === 'nw') {
        newDirection = 'ne';
        newRotationDirection = 'Right';

        position = 5;
      } else if (direction === 'sw') {
        newDirection = 'se';
        newRotationDirection = 'Right';

        position = 6;
      }

      newColor = this.getRandomFromArray(COLORS);
    }

    if (translateX >= xMax) {
      if (direction === 'ne') {
        newDirection = 'nw';
        newRotationDirection = 'Left';

        position = 7;
      } else if (direction === 'se') {
        newDirection = 'sw';
        newRotationDirection = 'Left';

        position = 8;
      }

      newColor = this.getRandomFromArray(COLORS);
    }
  
    this.setState({
      direction: newDirection,
      translateX,
      translateY,
      color: newColor,
      rotationDirection: newRotationDirection,
      debugPosition: position
    });
  }

  getRandomFromArray(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  render() {
    const {
      rotationDirection,
      translateX,
      translateY,
      isShown,
      color,
      debug,
    } = this.state;
    const debugContainer = [];

    if (!isShown) {
      return null;
    }

    if (debug) {
      debugContainer.push(
        <div key='debug' className='screenSaverCheckPositions'>
          <pre>
            {JSON.stringify(this.state, null, 2)}
          </pre>
        </div>
      );
    }

    return (
      <div className='screenSaverContainer'>
        {debugContainer}
        <div
          className='screenSaver'
          ref={element => this.divRef = element}
          style={{
            transform: 'translate3d(' + translateX + 'px, ' + translateY + 'px, 0)',
            color: color
          }}>
          <RotatingImage
            width='200px'
            height='200px'
            speed={3}
            direction={rotationDirection}
          >
            <Logo color={color} width='200px' height='200px'/>
          </RotatingImage>
        </div>
      </div>
    );
  }
}
