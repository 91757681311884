import { Component } from 'react';
import { get } from 'superagent';

import transformServiceOffers from '../../Transformers/serviceOffers';

import './services.scss';

import Typography from '../../Atoms/Typography/typography';
import TokenModal from '../../Molecules/TokenModal/tokenModal';
import LoadingModal from '../../Molecules/LoadingModal/loadingModal';
import ServiceOfferBox from '../../Molecules/ServiceOfferBox/serviceOfferBox';
import ServiceList from '../../Organisms/ServiceList/serviceList';
import ServiceOfferList from '../../Organisms/ServiceOfferList/serviceOfferList';
import Spacer from '../../Atoms/Spacer/spacer';

export default class Services extends Component {
  state = {
    services: [],
    serviceOffers: {},
    loading: true,
    error: false
  }

  componentDidMount() {
    document.title = "DataModelers - Services";

    this.loadServices();
    this.loadServiceOffers();
  }

  loadServiceOffers() {
    const {
      REACT_APP_CMS_URL
    } = process.env;
    const jsonWebToken = localStorage.getItem('jsonWebToken');

    get(`${REACT_APP_CMS_URL}/service-offers`)
    .set('Authorization', `Bearer ${jsonWebToken}`)
    .end((err, res) => {
      if (err) {
        console.log(err.message)

        return;
      }

      this.setState({
        serviceOffers: transformServiceOffers(res.body)
      });
    });
  }

  loadServices() {
    const {
      REACT_APP_CMS_URL
    } = process.env;
    const jsonWebToken = localStorage.getItem('jsonWebToken');

    get(`${REACT_APP_CMS_URL}/services`)
    .set('Authorization', `Bearer ${jsonWebToken}`)
    .end((err, res) => {
      if (err) {
        console.log(err.message)
        this.setState({
          error: err
        }, () => this.setState({ loading: false }));

        return;
      }

      this.setState({
        services: res.body
      }, () => this.setState({ loading: false }));
    });
  }

  render() {
    const {
      error,
      loading,
      services,
      serviceOffers
    } = this.state;

    
    if(loading) {
      return <LoadingModal/>;
    }

    if(error) {
      return <TokenModal />;
    }
    
    let returnServiceOffersArray = [] 

    let returnArray = [
      <ServiceList key='serviceList' services={services} />
    ];

    const countServiceOffers = Object.keys(serviceOffers).length;
    if(countServiceOffers > 0) {
      returnServiceOffersArray.push(
        <ServiceOfferList serviceOffers={serviceOffers}/>
      )
    }


    return (
      <div className='servicePage'>
        <Typography component='h1'>
          Our Service Offers
        </Typography>
        <ServiceOfferBox serviceOffers={serviceOffers} withGoToPageLink/>

        <div className='serviceWrapper'>
          <Typography component='h1'>
            Services
          </Typography>
          {returnArray}
        </div>
        <Spacer space={100}/>
      </div>
    );
  }
}
