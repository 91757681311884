import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';

import './link.scss';

export default function NavigationLink(props) {
  const location = useLocation();
  const classNames = [
    'navigationLink'
  ];

  if (location.pathname === props.path) {
    classNames.push(
      'currentPage'
    )
  }

  return (
    <div className={classNames.join(' ')}>
      <Link
        to={props.path}
        key={props.shortName}
      >
        {props.name}
      </Link>
    </div>
  );
}

NavigationLink.propTypes = {
  path: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
