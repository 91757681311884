import './loadingModal.scss';

import Logo from '../../Atoms/Logo/logo';
import RotatingImage from '../../Molecules/RotatingImage/rotatingImage';

function LoadingModal(props) {
    let rotationSpeed = 25;


    return <div className='loadingModal'>
      <RotatingImage width='70px' height='70px' speed={2} direction={'Left'}>
        <Logo width='70px' height='70px'/>
      </RotatingImage>
    </div>;
}

export default LoadingModal;
