import PropTypes from 'prop-types';

import './blogPostList.scss';

import Image from '../../Atoms/Image/image';
import Card from '../../Molecules/Card/card';
import CardTitle from '../../Molecules/Card/cardTitle';
import CardShortText from '../../Molecules/Card/cardShortText';

export default function BlogPostList(props) {
  const {
    REACT_APP_CMS_URL
  } = process.env;
  const returnArray = [];

  if (props.blogPosts.length === 0) {
    return 'No Blog Posts yet'; 
  }

  props.blogPosts.map(blogPost => 
    returnArray.push(
      <Card
      key={blogPost.slugs ? blogPost.slugs : Math.random()}
      link={blogPost.slugs ? `/blog/${blogPost.slugs}` : null}
      // backgroundImage={blogPost.covers ? `${REACT_APP_CMS_URL}${blogPost.covers.url}` : null}
      >
        {blogPost.covers ? <Image src={`${REACT_APP_CMS_URL}${blogPost.covers.url}`}/> : null}
        {blogPost.titles ? <CardTitle title={blogPost.titles}/> : null}
        {blogPost.intro_texts ? <CardShortText text={blogPost.intro_texts}/> : null}
      </Card>
    )
  );

  return (
    <div className='blogPostList'>
      {returnArray}
    </div>
  );
}

BlogPostList.propTypes = {
  blogPosts: PropTypes.array.isRequired
};
