import PropTypes from 'prop-types';

import './serviceList.scss';

import ServiceTable from '../../Molecules/ServiceTable/serviceTable';

export default function ServiceList(props) {
  if (props.services.length === 0) {
    return 'No Services yet'; 
  }

  return (
    <div className='serviceList'>
      <ServiceTable services={props.services}/>
    </div>
  );
}

ServiceList.propTypes = {
  services: PropTypes.array.isRequired
};
