import PropTypes from 'prop-types';

import './upcomingBlogPostList.scss';

import Typography from '../../Atoms/Typography/typography';

export default function UpcomingBlogPostList(props) {  
  const returnArray = [];

  if (props.upcominBlogPosts.length === 0) {
    return (
      <Typography
      component='caption'
      >
        No Upcoming Blog Posts
      </Typography>
    );
  }

  props.upcominBlogPosts.map(blogPost => 
    returnArray.push(
      <Typography
      key={Math.random()}
      component='h3'
      >
        {blogPost.titles}
      </Typography>
    )
  );

  return (
    <div className='upComingBlogPostList'>
      {returnArray}
    </div>
  );
}

UpcomingBlogPostList.propTypes = {
  upcominBlogPosts: PropTypes.array.isRequired
};
