import './divider.scss';

export default function Divider(props) {
  const {
    lineWidth
  } = props;

  return <div className='divider'>
    <svg height="1" width="100%">
      <line x1="0" y1="0" x2={`${lineWidth}%`} y2="0" />
      <line x1={`${100 - lineWidth}%`} y1="0" x2="100%" y2="0" />
    </svg>
  </div>;
}