import { Component } from 'react';
import { get } from 'superagent';
import transformServiceOffers from '../../Transformers/serviceOffers';

import './serviceOffers.scss';

import Typography from '../../Atoms/Typography/typography';
import TokenModal from '../../Molecules/TokenModal/tokenModal';
import LoadingModal from '../../Molecules/LoadingModal/loadingModal';
import ServiceOfferBox from '../../Molecules/ServiceOfferBox/serviceOfferBox';
import ServiceOfferList from '../../Organisms/ServiceOfferList/serviceOfferList';
import Spacer from '../../Atoms/Spacer/spacer';

class ServiceOffers extends Component {
  state = {
    serviceOffers: {},
    loadingServiceOffers: true,
    errorServiceOffers: false
  }

  componentDidMount() {
    document.title = "DataModelers - Service Offers";

    this.loadServiceOffers();
  }

  loadServiceOffers() {
    const {
      REACT_APP_CMS_URL
    } = process.env;
    const jsonWebToken = localStorage.getItem('jsonWebToken');

    get(`${REACT_APP_CMS_URL}/service-offers`)
    .set('Authorization', `Bearer ${jsonWebToken}`)
    .end((err, res) => {
      if (err) {
        this.setState({
          errorServiceOffers: err,
          loadingServiceOffers: false
        });


        return;
      }

      this.setState({
        serviceOffers: transformServiceOffers(res.body),
        loadingServiceOffers: false
      });
    });
  }

  render() {
    const {
      serviceOffers,
      errorServiceOffers,
      loadingServiceOffers
    } = this.state;

    if(errorServiceOffers) {
      return <TokenModal />;
    }

    if(loadingServiceOffers) {
      return <LoadingModal/>;
    }

    return (
      <div className='serviceOffersPage'>
        <Typography component='h1'>
          Service Offers
        </Typography>
        <ServiceOfferBox serviceOffers={serviceOffers} withSeeBelowText/>
        <Typography component='h1'>
          Pending Service Offers
        </Typography>
        <ServiceOfferList serviceOffers={serviceOffers}/>
        <Spacer space={100}/>
      </div>
    );
  }
}

export default ServiceOffers;
