import { Fragment } from 'react';
import PropTypes from 'prop-types';

import './serviceOfferList.scss';
import { Link } from 'react-router-dom';
import Typography from '../../Atoms/Typography/typography';

export default function ServiceOfferList(props) {
  const {
    serviceOffers
  } = props;
  
  if (props.serviceOffers.length === 0) {
    return 'No Service Offers yet'; 
  }
  const returnServiceOffers = [];

  Object.keys(serviceOffers).forEach(offerNumber => {
    const offerVersions = Object.keys(serviceOffers[offerNumber]).length;

    returnServiceOffers.push(
      <Link
        to={`/service-offers/${offerNumber}`}
        key={offerNumber}
      >
        <div className='serviceOfferListElement'>
          <div className={`pulse approval`}/>
          <Typography component='text'>
            Service Offer: FFR-2022-{offerNumber}-{100 + offerVersions}.
          </Typography>
        </div>
        <div className='clear'/>
      </Link>
    );
  });

  return (
    <div className='serviceOfferList'>
      {returnServiceOffers}
    </div>
  );
}

ServiceOfferList.propTypes = {
  serviceOffers: PropTypes.object.isRequired
};
