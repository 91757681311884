const TABLE_TEXTS = {
  de: {
    minimum_order_quantities: 'Minimum Order Quantities',
    service_descriptions: 'Descriptions',
    request_pricings: 'Select Services',
    unit_prices: 'Unit Prices',
    currencies: 'Currencies',
    services: 'Services',
    units: 'Units',
    icons: '',
    amount: 'Amount',
  }
}

function tableTexts(language, selector) {
  if(
    TABLE_TEXTS[language]
    && TABLE_TEXTS[language][selector] != null
  ) {
    return TABLE_TEXTS[language][selector];
  }

  return `${selector} does not exist`;
}

export default tableTexts;