import ReactMarkdown from 'react-markdown';

import './typography.scss';

function Typography(props) {
  let component = null;
  let classNames = [];

  if(props.component.toLowerCase() === 'h1') {
    component = <h1>
      {props.children}
    </h1>
  }

  if(props.component.toLowerCase() === 'h2') {
    component = <h2>
      {props.children}
    </h2>
  }

  if(props.component.toLowerCase() === 'h3') {
    component = <h3>
      {props.children}
    </h3>
  }

  if(props.component.toLowerCase() === 'link') {

    component = <a
      href={props.href}
      className='link'
    >
      {props.children}
    </a>
  }

  if(props.component.toLowerCase() === 'subtitle') {
    component = <span className='subtitle'>
      {props.children}
    </span>
  }

  if(props.component.toLowerCase() === 'description') {
    component = <span className='description'>
      {props.children}
    </span>
  }

  if(props.component.toLowerCase() === 'caption') {
    component = <span className='caption'>
      {props.children}
    </span>
  }

  if(props.component.toLowerCase() === 'tableheader') {
    component = <span className='tableHeader'>
      {props.children}
    </span>
  }

  if(props.component.toLowerCase() === 'text') {
    component = <span>
      {props.children}
    </span>
  }

  if(props.component.toLowerCase() === 'markdown') {
    component = <ReactMarkdown
      children={props.children}
      className='markdown'
    />
  }

  if(props.className) {
    classNames.push(props.className);
  }

  if(props.textAlign) {
    classNames.push(`textAlign__${props.textAlign}`);
  }

  if(props.fontWeight) {
    classNames.push(`fontWeight__${props.fontWeight}`);
  }

  if(props.fontStyle) {
    classNames.push(`fontStyle__${props.fontStyle}`);
  }

  if(props.fontSize) {
    classNames.push(`fontSize__${props.fontSize}`);
  }

  return <div
    className={
      `typography${classNames.length > 0 ?
        ` ${classNames.join(' ')}` :
        ''
      }`
    }
  >
    {component}
  </div>;
}

Typography.defaultProps = {
  component: 'h1',
  children: null
};

export default Typography;
