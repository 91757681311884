import PropTypes from 'prop-types';

import './cardTitle.scss';

import Typography from '../../Atoms/Typography/typography';

export default function CardTitle(props) {
  return (
    <Typography className='cardTitle' component='h2'>
      {props.title}
    </Typography>
  );
}

CardTitle.propTypes = {
  title: PropTypes.string.isRequired
};
