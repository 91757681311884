import './table.scss';

import Typography from '../../Atoms/Typography/typography';

function toLowercaseWithoutUnderscore(string) {
  return string.split('_').join(' ').toUpperCase();
}

function TableHeader(props) {
  const {
    data,
    columns,
  } = props;

  let tableHeaderRowKeys = tableKeys(data, columns);

  if (columns) {
    tableHeaderRowKeys = Object.keys(columns);
  }

  return (
    <thead className='tableHead'>
      <tr>
        {tableHeaderRowKeys.map(key => (
          <th key={key}>
            <Typography
              component='text'
              textAlign={columns[key] && columns[key].text_align ? columns[key].text_align : 'center'}
              fontWeight={600}
            >
              {
              columns && columns[key] && columns[key].mapped_name
                ? columns[key].mapped_name
                : toLowercaseWithoutUnderscore(key)
            }
            </Typography>
          </th>
        ))}
      </tr>
    </thead>
  );
}

function TableBody(props) {
  const {
    data,
    columns
  } = props;
  const tableRows = [];

  let tableHeaderRowKeys = tableKeys(data, columns);
  if(columns) {
    tableHeaderRowKeys = Object.keys(columns);
  }

  Object.keys(data).forEach(key => {
    const tableColumns = [];

    tableHeaderRowKeys.forEach(columnNameKey => {
      tableColumns.push(
        <td key={columnNameKey}>
          <Typography
            component='text'
            textAlign={
              columns[columnNameKey]
              && columns[columnNameKey].text_align
                ? columns[columnNameKey].text_align : 'center'}
          >
            {data[key][columnNameKey]}
          </Typography>
        </td>
      )
    });

    tableRows.push(<tr key={key}>{tableColumns}</tr>);
  });

  return (
    <tbody className='tableBody'>
      {tableRows}
    </tbody>
  );
}

function tableKeys(data) {
  const tableHeaderRowKeys = [];

  Object.keys(data).forEach(key => {
    Object.keys(data[key]).forEach(key2 => {
      const tableHeaderRow = key2;

      if(!tableHeaderRowKeys.includes(tableHeaderRow)) {
        tableHeaderRowKeys.push(tableHeaderRow);
      }
    });
  });

  return tableHeaderRowKeys;
}

export default function Table(props) {
  const {
    REACT_APP_SEPARATOR
  } = process.env;
  const {
    data,
    columns
  } = props;

  return (
    <div className={`organisms-${REACT_APP_SEPARATOR.toLowerCase()}-table`}>
      <table>
        <TableHeader data={data} columns={columns}/>
        <TableBody data={data} columns={columns}/>
      </table>
    </div>
  )
}
