import { Component } from 'react';
import superagent from 'superagent';

import './requestServiceModal.scss';

import Typography from '../../Atoms/Typography/typography';
import Spacer from '../../Atoms/Spacer/spacer';
import Button from '../../Atoms/Button/button';

class RequestServiceModal extends Component {
  state = {
    requestingServices: false,
    requestedServices: false
  }

  transformServices() {
    const {
      services,
    } = this.props;
    let returnServicesObject = {};

    services.map(service => (
      returnServicesObject[service.id] = service
    ));

    return returnServicesObject;
  }

  requestServices() {
    this.setState({
      requestingServices: true
    }, () => this.request())
  }

  request() {
    const {
      eMail,
      selectedServices
    } = this.props;
    const {
      REACT_APP_CMS_URL
    } = process.env;
    const services = this.transformServices();
    const jsonWebToken = localStorage.getItem('jsonWebToken');
    const selectedServicesArray = Object.keys(selectedServices);

    let countPostedServices = 0;
  
    selectedServicesArray.forEach(key => {
      superagent.post(`${REACT_APP_CMS_URL}/service-requests`)
      .set('Authorization', `Bearer ${jsonWebToken}`)
      .send({
        tracing_tokens: jsonWebToken ? jsonWebToken : '',
        request_services: services[key].services,
        request_service_ids: key,
        request_emails: eMail
      })
      .end((err, res) => {
        if (err) {
          console.log(err);

          return;
        }

        countPostedServices = countPostedServices + 1;

        if (countPostedServices === Object.keys(selectedServices).length) {
          this.setState({
            requestingServices: false,
            requestedServices: true
          });
        }
      });
    });
  }

  render() {
    const {
      eMail,
      selectedServices
    } = this.props;
    const {
      requestedServices,
      requestingServices
    } = this.state;
    const services = this.transformServices();
    const returnSelectedServices = [];

    Object.keys(selectedServices).forEach(key => {
      if(selectedServices[key]) {
        returnSelectedServices.push(
          <span key={key}>
            {services[key].services}<br/>
          </span>
        )
      }
    });

    return <div className='requestServiceModal'>
      <div className='requestServiceModalInner'>
      {!requestedServices ? 
        <div className='requestingArea'>
          <Typography component='h1'>
            Last Check.
          </Typography>
          <Typography component='h2'>
            Do you want to request the following services?
          </Typography>
          <Spacer space={50}/>
          <Typography component='h3'>
            Requesting Services:
          </Typography>
          {returnSelectedServices}
          <Spacer space={50}/>
          <Typography component='h3'>
            Your E-Mail: 
          </Typography>
          {eMail}
          <Spacer space={50}/>
          <Button
            onClick={() => !requestingServices ? this.requestServices() : null}
          >
            {!requestingServices ? 'Request now' : 'Requesting Services'}
          </Button>
        </div> 
        : 
        <div className='requestedArea'>
          <Typography component='h1'>
            Requested.
          </Typography>
          <Typography component='h1'>
            Thank you {eMail}
          </Typography>
          <Typography component='h2'>
            we will come back to you
          </Typography>
        </div>
        }
      </div>
    </div>;
  }
}

export default RequestServiceModal;
