import { Link } from 'react-router-dom';

import './card.scss';

export default function Card(props) {
  let style = {};

  if (props.backgroundImage) {
    style.backgroundImage = `url(${props.backgroundImage})`;
  }

  if (props.link) {
    return (
      <Link
      to={props.link}
      key={'logoHomeClick'}
      className='card'
      style={style}
      >
        {props.children}
      </Link>
    );
  }

  return (
    <div className='card' style={style}>
      {props.children}
    </div>
  );
}
