import { Component } from 'react';

import './rotatingText.scss';

const rotationSpeed = 3000;

class RotatingText extends Component {
  state = {
    countWords: this.props.words.length - 1,
    currentWord: 0,
    currentLetter: 0,
  };

  componentDidMount() {
    /* Rotate words */
    this.rotateWords = setInterval(() => {
      let currentWord = this.state.currentWord + 1;

      if (this.state.countWords < currentWord) {
        currentWord = 0;
      }

      this.setState({
        currentWord,
        currentLetter: 0
      });
    }, rotationSpeed);

    /* Add letters */
    this.addLetter = setInterval(() => {
      const currentLetter = this.state.currentLetter + 1;

      this.setState({
        currentLetter: currentLetter
      });
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.rotateWords)
    clearInterval(this.addLetter)
  }

  render() {
    const classNames = [
      'rotatingWord'
    ];

    return <h2 className='rotatingText'>
      <span className='text'>
        {this.props.text}
      </span>
      <span className={classNames.join(' ')}>
        {this.props.words[this.state.currentWord].substring(0, this.state.currentLetter)}
        <span className='blinkingCursor'>
          |
        </span>
      </span>
    </h2>;
  }
}

export default RotatingText;
