import React, { Component } from 'react';
import superagent from 'superagent';
import queryString from 'query-string';

import './main.scss';

import Header from '../../Organisms/Header/header';
import ScreenSaver from '../../Organisms/ScreenSaver/screenSaver';
import NavigationBar from '../../Organisms/NavigationBar/navigationBar';

export default class Template extends Component {
  info() {
    let path = '';
    let domain = '';
    let browserName = '';
    let operatingSystem = '';
    
    if (window.navigator) {
      browserName = window.navigator.vendor
    }

    if (
      window.navigator
      && window.navigator.userAgentData
    ) {
      operatingSystem = window.navigator.userAgentData.platform
    }

    if (
      window.location
      && window.location.origin
    ) {
      domain = window.location.origin;
    }

    if (
      window.location
      && window.location.pathname
    ) {
      path = window.location.pathname;
    }

    const jsonWebToken = localStorage.getItem('jsonWebToken');

    const info = {
      urls_dp_url_domains: domain,
      urls_dp_url_pathes: path,
      users_dp_user_checked_ins: jsonWebToken ? 'yes' : 'no',
      users_dp_user_tokens: jsonWebToken ? jsonWebToken: null,
      events_dp_event_names: 'view',
      browsers_dp_browser_names: browserName,
      operatingsystems_dp_operatingsystem_platforms: operatingSystem,
      metrics_dp_posted_ats: new Date().toISOString(),
      metrics_dp_application_loadtimes: 0
    };

    return info;
  }

  getParam(params, param) {
    const paramsObject = queryString.parse(params);

    if(paramsObject[param]) {
      return paramsObject[param];
    }

    return false;
  }

  getTokenFromQueryString() {
    if (
      window.location
      && window.location.search
    ) {
      const jsonWebToken = localStorage.getItem('jsonWebToken');
      const token = this.getParam(window.location.search, 'token');

      if(!jsonWebToken && token) {
        localStorage.setItem('jsonWebToken', token);
        window.location.reload();
      }
    }
  }

  componentWillMount() {
    this.getTokenFromQueryString();
  }

  componentDidMount() {
    this.getTokenFromQueryString();
    this.postWebMetrics();
  }

  componentWillReceiveProps() {
    this.getTokenFromQueryString();
    this.postWebMetrics();
  }

  postWebMetrics() {
    const {
      REACT_APP_TRACKING_URL
    } = process.env;

    superagent.post(`${REACT_APP_TRACKING_URL}`)
    .send(this.info())
    .end((err, res) => {

    });
  }

  render() {
    const {
      children
    } = this.props;

    return (
      <div className='dataModelers' >
        <Header subtitle='Delivering your Data as you need' rotation={true}/>
        {children}
        <NavigationBar/>
        {/* <ScreenSaver/> */}
      </div>
    );
  }
}
