import PropTypes from 'prop-types';
import './notifications.scss';

export default function Notifications(props) {
  const returnNotifications = [];
  const classNames = [
    'nofifications'
  ];

  props.notifications.map(notification => (
    returnNotifications.push(
      <div key={Math.random()} className={`banner ${notification.level}`}>
        {notification.message}
      </div>
    )
  ));

  return (
    <div className={classNames.join(' ')}>
      {returnNotifications}
    </div>
  );
}

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired
};
