import { Component } from 'react';
import { get } from 'superagent';
import moment from 'moment';

import './serviceOffer.scss';

import transformServiceOffers from '../../Transformers/serviceOffers';

import Logo from '../../Atoms/Logo/logo';
import Spacer from '../../Atoms/Spacer/spacer';
import QrCode from '../../Atoms/QrCode/qrCode';
import Divider from '../../Atoms/Divider/divider';
import Typography from '../../Atoms/Typography/typography';
import Grid from '../../Molecules/Grid/grid';
import TokenModal from '../../Molecules/TokenModal/tokenModal';
import LoadingModal from '../../Molecules/LoadingModal/loadingModal';
import Table from '../../Organisms/Table/table';
import Header from '../../Organisms/Header/header';

const DOCUMENT_TITLE = window.location.pathname.replace('/service-offers/', '');

class ServiceOffer extends Component {
  rotatingOfferText = [
    'decentralize',
    'we won\'t stop',
  ];

  state = {
    serviceOffer: {},
    serviceOfferId: DOCUMENT_TITLE,
    loadingServiceOffer: true,
    errorServiceOffer: false,
    documentTitle: `DataModelers - Service Offer - ${DOCUMENT_TITLE}`
  }

  componentDidMount() {
    document.title = `DataModelers - Service Offer - ${this.state.serviceOfferId}`;

    this.loadServiceOffer();
  }

  removePluralS(string) {
    const endsWithS = string.endsWith("s");

    if(!endsWithS) {
      return string;
    }

    return string.slice(0, -1);
  }

  offerLink() {
    const jsonWebToken = localStorage.getItem('jsonWebToken');
    const {
      serviceOfferId,
    } = this.state;
    const {
      REACT_APP_PAGE_FRONTEND_URL
    } = process.env;

    return `${REACT_APP_PAGE_FRONTEND_URL}/service-offers/${serviceOfferId}?token=${jsonWebToken}`;
  }

  qrCodeLink() {
    const jsonWebToken = localStorage.getItem('jsonWebToken');
    const {
      documentTitle,
      serviceOfferId,
    } = this.state;
    const {
      REACT_APP_PAGE_FRONTEND_URL
    } = process.env;
    const mailTo = 'hello@datamodelers.de';
    const subject = documentTitle;

    const body = `Our service offer no: ${serviceOfferId}

View this offer online and explore the world of the datamodelers.

Offer: ${this.offerLink()}

Imprint: https://datamodelers.de/imprint
    `;

    return (
      `mailto:${mailTo}` +
      `?subject=${subject}` +
      `&body=${encodeURIComponent(body)}`
    );
  }

  mailToLink(offer) {
    const {
      documentTitle,
      serviceOfferId
    } = this.state;
    const mailTo = 'hello@datamodelers.de';
    const subject = documentTitle;
    const returnServiceOffers = [];

    Object.keys(offer.lines).forEach(key => {
      returnServiceOffers.push(
        `-- ${offer.lines[key].service_names}: ` 
        +
        `Offered ${offer.lines[key].quantities} ${offer.lines[key].unit_names.toLowerCase()} ` 
        +
        `for ${offer.lines[key].amounts} ${offer.lines[key].currency_symbols} per ${this.removePluralS(offer.lines[key].unit_names.toLowerCase())}. --`
      )
    });

    const body = `Our service offer no: ${serviceOfferId}

See our offered services below:

${returnServiceOffers.join('\n')}

View this offer online and explore the world of the datamodelers.

Offer: ${this.offerLink()}

Imprint: https://datamodelers.de/imprint
`;

    return (
      `mailto:${mailTo}` +
      `?subject=${subject}` +
      `&body=${encodeURIComponent(body)}`
    );
  }

  loadServiceOffer() {
    const {
      REACT_APP_CMS_URL
    } = process.env;
    const jsonWebToken = localStorage.getItem('jsonWebToken');

    get(`${REACT_APP_CMS_URL}/service-offers`)
    .set('Authorization', `Bearer ${jsonWebToken}`)
    .end((err, res) => {
      if (err) {
        this.setState({
          errorServiceOffer: err,
          loadingServiceOffer: false
        });

        return;
      }

      this.setState({
        serviceOffer: transformServiceOffers(res.body),
        loadingServiceOffer: false
      });
    });
  }

  serviceOfferDocumentFooterBox(offerHead) {
    return (
      <div className='documentFooterBox'>
        <table>
          <tbody>
            <tr>
              <td>
                <Typography component='text' textAlign='right'>
                  Contact Name.
                </Typography>
              </td>
              <td>
                <Typography component='text' textAlign='left'>
                  {offerHead.bill_from_contacts}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='right'>
                  Contact Address.
                </Typography>
              </td>
              <td>
                <Typography component='text' textAlign='left'>
                  {offerHead.bill_from_streets}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='right'>
                  Contact Postal Code.
                </Typography>
              </td>
              <td>
                <Typography component='text' textAlign='left'>
                  {offerHead.bill_from_postal_codes}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='right'>
                  Contact City.
                </Typography>
              </td>
              <td>
                <Typography component='text' textAlign='left'>
                  {offerHead.bill_from_cities}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='right'>
                  Contact Phone.
                </Typography>
              </td>
              <td>
                <Typography component='text' textAlign='left'>
                  XXX-XXX-XXX-XXX
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='right'>
                  Contact E-Mail.
                </Typography>
              </td>
              <td>
                <Typography component='text' textAlign='left'>
                  XXX-XXX-XXX-XXX
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='right'>
                  Contact BIC.
                </Typography>
              </td>
              <td>
                <Typography component='text' textAlign='left'>
                  XXX-XXX-XXX-XXX
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='right'>
                  Contact IBAN.
                </Typography>
              </td>
              <td>
                <Typography component='text' textAlign='left'>
                  XXX-XXX-XXX-XXX
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='right'>
                  Contact ETH.
                </Typography>
              </td>
              <td>
                <Typography component='text' textAlign='left'>
                  XXX-XXX-XXX-XXX
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  serviceOfferDocumentContactBox(offerHead) {
    return (
      <div className='documentRecipientBox'>
        <table>
          <tbody>
           <tr>
             <td>
                <Typography component='text' textAlign='left'>
                  {offerHead.bill_to_contacts}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='left'>
                  c/o {offerHead.bill_to_company_names}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='left'>
                {offerHead.bill_to_streets}
                </Typography>
              </td>
            </tr>
            <tr>
               <td>
                <Typography component='text' textAlign='left'>
                  {offerHead.bill_to_postal_codes} {offerHead.bill_to_cities}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='left'>
                  {offerHead.bill_to_countries}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  serviceOfferDocumentInformationBox(offerDate, offerName) {
    return (
      <div className='documentInformationBox'>
        <table>
          <tbody>
            <tr>
              <td>
                <Typography component='text' textAlign='right'>
                  Offer Date.
                </Typography>
              </td>
              <td>
                <Typography component='text' textAlign='left'>
                  {moment(offerDate).format('DD.MM.Y')}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='right'>
                  Offer Name.
                </Typography>
              </td>
              <td>
                <Typography component='text' textAlign='left'>
                  {offerName}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography component='text' textAlign='right'>
                  Customer Number.
                </Typography>
              </td>
              <td>
                <Typography component='text' textAlign='left'>
                  -
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  OfferSumUp(props) {
    const collect = {};
    const data = props.data;
    const returnArray = [];

    Object.keys(data).forEach(key => {
      if(!collect[data[key].currency_names]) {
        collect[data[key].currency_names] = 0;
      }

      collect[data[key].currency_names] += data[key].amount_totals;
    });

    Object.keys(collect).forEach(sepearateBy => {
      returnArray.push(
        <Typography
        component='text'
        textAlign='right'
        >
          Total offered amount in {sepearateBy}: {collect[sepearateBy]}
        </Typography>
      );
    });

    return <Grid container>
      <Grid columns={3} spacing={0}>
      </Grid>
      <Grid columns={3} spacing={0}>
      </Grid>
      <Grid columns={3} spacing={0}>
        {returnArray}
      </Grid>
    </Grid>
  }

  render() {
    const {
      serviceOffer,
      serviceOfferId,
      errorServiceOffer,
      loadingServiceOffer
    } = this.state;
    const jsonWebToken = localStorage.getItem('jsonWebToken');

    if(errorServiceOffer) {
      return <TokenModal/>;
    }

    if(loadingServiceOffer) {
      return <LoadingModal/>;
    }

    const serviceOfferVersionsArray = [];

    Object.keys(serviceOffer[serviceOfferId]).forEach(version => {
      serviceOfferVersionsArray.push(
        <option key={version} value={version}>{version}</option>
      );
    });

    const serviceOfferVersions = serviceOfferVersionsArray.length;
    // TODO: This may be unsafe
    const highestOfferVersion = 100 + serviceOfferVersions;
    const offer = serviceOffer[serviceOfferId][highestOfferVersion];
    const offerName = `FFR-${new Date(offer.head.offer_dates).getFullYear()}-${serviceOfferId}`;
    const offerNameVersion = <>
      {offerName}
      -
      <select defaultValue={highestOfferVersion}>
        {serviceOfferVersionsArray}
      </select>
    </>;


    const mailToLink = this.mailToLink(offer);
    const qrCodeLink = this.qrCodeLink();

    const tableColumns = {
      service_names: {
        mapped_name: 'Service Names',
        text_align: 'right'
      },
      service_descriptions: {
        mapped_name: 'Service Descriptions',
        text_align: 'left'
      },
      quantities: {
        mapped_name: 'Offered Quantities',
        text_align: 'right'
      },
      unit_names: {
        mapped_name: 'Unit Names',
        text_align: 'left'
      },
      amounts: {
        mapped_name: 'Amounts per Unit',
        text_align: 'right',
      },
      currency_symbols: {
        mapped_name: 'Currencies',
        text_align: 'left'
      },
      amount_totals: {
        mapped_name: 'Offered Amounts',
        text_align: 'right'
      }
    };

    return (
      <div className='serviceOfferPage'>
        <div className='paper'>
          <Header subtitle={`[...${this.rotatingOfferText[0]}...]`} rotation={false}/>
          <Spacer space={100}/>
          <Grid container>
            <Grid columns={1} spacing={0}>
              <Typography
                component='text'
                fontStyle='italic'
                textAlign='left'
                fontSize='small'
              >
                {offer.head.bill_from_company_names},
                {offer.head.bill_from_streets},
                {offer.head.bill_from_postal_codes} {offer.head.bill_from_cities}
              </Typography>
            </Grid>
            <Spacer space={20}/>
            <Grid columns={2} spacing={0}>
              {this.serviceOfferDocumentContactBox(offer.head)}
            </Grid>
            <Grid columns={2} spacing={0}>
              {this.serviceOfferDocumentInformationBox(offer.head.offer_dates, offerNameVersion, null)}
            </Grid>
          </Grid>

          <div className='clear'/>
          <Spacer space={100}/>

          <Divider lineWidth={20}/>
          <Table
            data={offer.lines}
            columns={tableColumns}
          />

          <Divider lineWidth={20}/>
          <Grid clear/>
          <Spacer space={20}/>
          <this.OfferSumUp data={offer.lines} sumColumn='amount_totals' sepearateBy='currency_symbols'/>
          <Divider lineWidth={40}/>
          <Spacer space={100}/>

          <Grid container>
            <Grid columns={4} spacing={10}>
              <Typography
                component='text'
                textAlign='left'
              >
                <Logo/>
              </Typography>
            </Grid>
            <Grid columns={2} spacing={10}>
              {this.serviceOfferDocumentFooterBox(offer.head)}
            </Grid>
            <Grid columns={4} spacing={10}>
              <div className='documentTypeTypo'>
                <Typography
                  component='text'
                  textAlign='right'
                  fontSize='extraLarge'
                  fontWeight={600}
                >
                  OFFER
                </Typography>
                <Grid columns={1} textAlign='right'>
                <Typography
                  component='text'
                  textAlign='right'
                >
                  <QrCode
                  value={qrCodeLink}
                  />
                </Typography>
                </Grid>
                <Typography
                  component='link'
                  fontWeight={600}
                  textAlign='right'
                  href={mailToLink}
                >
                  MAIL OFFER TO
                </Typography>
              </div>
            </Grid>
            <Grid clear/>
          </Grid>
        </div>
        <Spacer space={100}/>
      </div>
    );
  }
}

export default ServiceOffer;
