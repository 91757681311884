export default function Logo(props) {
  let color = '#131313';
  let width = '100px';
  let height = '100px';

  if (props.color) {
    color = props.color;
  }

  if (props.width) {
    width = props.width;
  }

  if (props.height) {
    height = props.height;
  }


  const connectorStyle = {
    fill: color,
    stroke: color,
    strokeMiterlimit: 10
  };

  const connectorLinesInnerStyle = {
    fill: color
  };

  const connectorLinesStrokeStyle = {
    fill: color
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      width={width}
      height={height}
    >
      <path style={connectorStyle} d="M272.31,51.7a22,22,0,0,1,.16,2.69,23.47,23.47,0,0,1-46.94,0q0-1,.09-2a35.42,35.42,0,0,0,46.69-.65m2.12-12.2a29.47,29.47,0,0,1-51.28.73,29.47,29.47,0,1,0,51.28-.73Z"/>
      <path style={connectorStyle} d="M249,422.14a23.5,23.5,0,0,1,23.47,23.47q0,1-.09,2a35.42,35.42,0,0,0-46.69.65,22,22,0,0,1-.16-2.69A23.5,23.5,0,0,1,249,422.14m0-6h0a29.47,29.47,0,0,0-25.43,44.36,29.47,29.47,0,0,1,51.28-.73A29.48,29.48,0,0,0,249,416.14Z"/>
      <path style={connectorStyle} d="M249,239.16A10.84,10.84,0,1,1,238.16,250,10.85,10.85,0,0,1,249,239.16m0-6A16.84,16.84,0,1,0,265.84,250,16.84,16.84,0,0,0,249,233.16Z"/>
      <path style={connectorLinesInnerStyle} d="M249,218a2.5,2.5,0,0,1-2.5-2.5V94.23a2.5,2.5,0,0,1,5,0V215.48A2.5,2.5,0,0,1,249,218Z"/>
      <path style={connectorLinesStrokeStyle} d="M249,92.23a2,2,0,0,1,2,2V215.48a2,2,0,1,1-4,0V94.23a2,2,0,0,1,2-2m0-1a3,3,0,0,0-3,3V215.48a3,3,0,1,0,6,0V94.23a3,3,0,0,0-3-3Z"/>
      <path style={connectorLinesInnerStyle} d="M249,408.27a2.5,2.5,0,0,1-2.5-2.5V284.52a2.5,2.5,0,0,1,5,0V405.77A2.5,2.5,0,0,1,249,408.27Z"/>
      <path style={connectorLinesStrokeStyle} d="M249,282.52a2,2,0,0,1,2,2V405.77a2,2,0,0,1-4,0V284.52a2,2,0,0,1,2-2m0-1a3,3,0,0,0-3,3V405.77a3,3,0,0,0,6,0V284.52a3,3,0,0,0-3-3Z"/>
      <path style={connectorStyle} d="M125.27,93.29a24.38,24.38,0,0,1,2,1.8,23.47,23.47,0,0,1-33.19,33.19c-.48-.48-.93-1-1.37-1.49a35.46,35.46,0,0,0,32.55-33.5m-7.14-10.12A29.49,29.49,0,0,1,82.39,120a29.48,29.48,0,1,0,35.74-36.78Z"/>
      <path style={connectorStyle} d="M387.32,364.85a23.3,23.3,0,0,1,16.59,6.87c.48.48.94,1,1.37,1.49a35.46,35.46,0,0,0-32.55,33.5,24.38,24.38,0,0,1-2-1.8,23.47,23.47,0,0,1,16.6-40.06m0-6a29.47,29.47,0,0,0-7.45,58,29.49,29.49,0,0,1,35.74-36.78,29.47,29.47,0,0,0-28.29-21.2Z"/>
      <path style={connectorLinesInnerStyle} d="M224.59,228.09a2.49,2.49,0,0,1-1.77-.73l-85.73-85.74a2.5,2.5,0,1,1,3.53-3.53l85.74,85.73a2.5,2.5,0,0,1-1.77,4.27Z"/>
      <path style={connectorLinesStrokeStyle} d="M138.85,137.85a2,2,0,0,1,1.42.59L226,224.18a2,2,0,1,1-2.82,2.82l-85.74-85.73a2,2,0,0,1,1.41-3.42m0-1a3,3,0,0,0-2.12,5.13l85.74,85.73a3,3,0,0,0,4.24,0,3,3,0,0,0,0-4.24L141,137.73a3,3,0,0,0-2.13-.88Z"/>
      <path style={connectorLinesInnerStyle} d="M359.15,362.65a2.46,2.46,0,0,1-1.77-.74l-85.74-85.73a2.5,2.5,0,0,1,3.54-3.54l85.73,85.74a2.51,2.51,0,0,1-1.76,4.27Z"/>
      <path style={connectorLinesStrokeStyle} d="M273.41,272.41a2,2,0,0,1,1.41.59l85.74,85.73a2,2,0,1,1-2.83,2.83L272,275.82a2,2,0,0,1,1.41-3.41m0-1a3,3,0,0,0-2.12,5.12L357,362.27a3,3,0,0,0,4.25-4.25l-85.74-85.73a3,3,0,0,0-2.12-.88Z"/>
      <path style={connectorStyle} d="M444.61,226.53a22,22,0,0,1,2.69.16,35.42,35.42,0,0,0-.65,46.69q-1,.09-2,.09a23.47,23.47,0,0,1,0-46.94m0-6a29.47,29.47,0,1,0,14.16,55.32,29.47,29.47,0,0,1,.73-51.28,29.38,29.38,0,0,0-14.89-4Z"/>
      <path style={connectorStyle} d="M53.39,226.53a23.47,23.47,0,1,1,0,46.94,22,22,0,0,1-2.69-.16,35.42,35.42,0,0,0,.65-46.69q1-.09,2-.09h0m0-6a29.3,29.3,0,0,0-14.16,3.62,29.47,29.47,0,0,1-.73,51.28,29.47,29.47,0,1,0,14.89-54.9Z"/>
      <path style={connectorLinesInnerStyle} d="M283.52,252.5a2.5,2.5,0,0,1,0-5H404.77a2.5,2.5,0,0,1,0,5Z"/>
      <path style={connectorLinesStrokeStyle} d="M404.77,248a2,2,0,0,1,0,4H283.52a2,2,0,0,1,0-4H404.77m0-1H283.52a3,3,0,0,0,0,6H404.77a3,3,0,0,0,0-6Z"/>
      <path style={connectorLinesInnerStyle} d="M93.23,252.5a2.5,2.5,0,0,1,0-5H214.48a2.5,2.5,0,1,1,0,5Z"/>
      <path style={connectorLinesStrokeStyle} d="M214.48,248a2,2,0,1,1,0,4H93.23a2,2,0,0,1,0-4H214.48m0-1H93.23a3,3,0,0,0,0,6H214.48a3,3,0,1,0,0-6Z"/>
      <path style={connectorStyle} d="M372.73,93.29a35.46,35.46,0,0,0,32.55,33.5c-.43.52-.89,1-1.37,1.49a23.47,23.47,0,0,1-33.19-33.19,24.38,24.38,0,0,1,2-1.8m7.14-10.12A29.47,29.47,0,1,0,415.61,120a29.47,29.47,0,0,1-35.74-36.78Z"/>
      <path style={connectorStyle} d="M110.68,364.85a23.47,23.47,0,0,1,16.6,40.06,24.38,24.38,0,0,1-2,1.8,35.46,35.46,0,0,0-32.55-33.5c.43-.52.89-1,1.37-1.49a23.3,23.3,0,0,1,16.59-6.87m0-6a29.47,29.47,0,0,0-28.29,21.2,29.47,29.47,0,0,1,35.74,36.78,29.47,29.47,0,0,0-7.45-58Z"/>
      <path style={connectorLinesInnerStyle} d="M273.41,228.09a2.5,2.5,0,0,1-1.77-4.27l85.74-85.73a2.5,2.5,0,1,1,3.53,3.53l-85.73,85.74A2.49,2.49,0,0,1,273.41,228.09Z"/>
      <path style={connectorLinesStrokeStyle} d="M359.15,137.85a2,2,0,0,1,1.41,3.42L274.82,227a2,2,0,1,1-2.82-2.82l85.73-85.74a2,2,0,0,1,1.42-.59m0-1a3,3,0,0,0-2.13.88l-85.73,85.74a3,3,0,0,0,2.12,5.12,3,3,0,0,0,2.12-.88L361.27,142a3,3,0,0,0-2.12-5.13Z"/>
      <path style={connectorLinesInnerStyle} d="M138.85,362.65a2.5,2.5,0,0,1-1.76-4.27l85.73-85.74a2.5,2.5,0,0,1,3.54,3.54l-85.74,85.73A2.46,2.46,0,0,1,138.85,362.65Z"/>
      <path style={connectorLinesStrokeStyle} d="M224.59,272.41a2,2,0,0,1,1.41,3.41l-85.73,85.74a2,2,0,1,1-2.83-2.83L223.18,273a2,2,0,0,1,1.41-.59m0-1a3,3,0,0,0-2.12.88L136.73,358a3,3,0,0,0,4.25,4.25l85.73-85.74a3,3,0,0,0-2.12-5.12Z"/>
    </svg>
  );
}
