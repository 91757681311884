import './home.scss';

import RotatingText from '../../Molecules/RotatingText/rotatingText';

export default function Home(props) {
  document.title = "DataModelers - Delivering your Data as you need";

  const rotateWords = [
    'Performance',
    'Traceability',
    'Simulation',
    'Business Rapidness',
    'Scaleability',
    'Compliance',
    'Documentation',
    'a Migration Strategy',
    'Innovation'
  ];

  return (
    <div className='homePage'>
      <div className='homeWrapper'>
        <RotatingText text='Bringing you' words={rotateWords}/>
      </div>
    </div>
  );
}
