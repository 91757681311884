import './rotatingImage.scss';

function RotatingImage(props) {
  let width = '100%';
  let height = '100%';
  let speed = 20;
  let direction = 'Right';

  if(props.width) {
    width = props.width;
  }

  if(props.height) {
    height = props.height;
  }

  if(props.speed) {
    speed = props.speed;
  }

  if(props.direction) {
    direction = props.direction;
  }
  
  return <div
    className='rotatingImage'
    style={{
      width: width,
      height: height,
      animation: `rotatingImageSpin${direction} infinite ${speed}s linear`
    }}
  >
    {props.children}
  </div>;
}

export default RotatingImage;
