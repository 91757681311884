import PropTypes from 'prop-types';

import './blogCategories.scss';

import BlogCategoryPill from '../../Atoms/BlogCategoryPill/blogCategoryPill';

export default function BlogCategories(props) {
  const returnCategories = [];

  props.categories.map(categoryObject => (
    returnCategories.push(
      <BlogCategoryPill key={categoryObject.id} name={categoryObject.category_name}/>
    )
  ));
  
  return <div
    key={'blogCategories'}
    className='blogCategories'
  >
    {returnCategories}
    <div className='clear'/>
  </div>;
}

BlogCategories.propTypes = {
  categories: PropTypes.array.isRequired
};
