import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import Router from './router';

import './reset.scss';
import './index.scss';

ReactDOM.render(
  <StrictMode>
    <Router/>
  </StrictMode>,
  document.getElementById('page')
);
