import React, { Component } from 'react';
import superagent from 'superagent';

import './tokenModal.scss';

import MailToLink from '../../Atoms/MailToLink/mailToLink';
import Typography from '../../Atoms/Typography/typography';
import LoadingModal from '../../Molecules/LoadingModal/loadingModal';

class TokenModal extends Component {
  state = {
    token: true,
    error: false,
    loading: false,
    tokenInput: '',
    userNameInput: '',
    passwordInput: ''
  }

  authenticate() {
    const {
      tokenInput,
      userNameInput,
      passwordInput
    } = this.state;
    const {
      REACT_APP_CMS_URL
    } = process.env;

    if (tokenInput) {
      localStorage.setItem('jsonWebToken', tokenInput);

      window.location.reload();
      return;
    }

    if(userNameInput && passwordInput) {
      superagent.post(`${REACT_APP_CMS_URL}/auth/local`)
      .send({
        identifier: userNameInput,
        password: passwordInput,
      })
      .end((err, res) => {
        if (err) {
          this.setState({
            error: err.message,
            loading: false
          });

          return;
        }

        if (res.body.user) {
          localStorage.setItem('jsonWebToken', res.body.jwt);
          window.location.reload();
        }
      });
    }
  }

  render() {
    const {
      token,
      error,
      loading,
      tokenInput,
      passwordInput,
      userNameInput
    } = this.state;

    if(loading) {
      return <LoadingModal/>;
    }

    let returnError = '';

    let returnArray = [
      <input
        key='tokenInput'
        className='tokenInput'
        placeholder='Enter Token and unlock our contents'
        defaultValue={tokenInput}
        onChange={(e) => this.setState({ tokenInput: e.target.value })}
        type='password'
      />
    ];
    let switchText = 'switch to username and password';
    let buttonClass = 'tokenSubmitButton';

    if(!token) {
      returnArray = [
        <input
          key='userNameInput'
          className='userNameInput'
          placeholder='User Name'
          defaultValue={userNameInput}
          onChange={(e) => this.setState({ userNameInput: e.target.value })}
          type='text'
        />,
        <input
          key='passwordInput'
          className='passwordInput'
          placeholder='Password'
          defaultValue={passwordInput}
          onChange={(e) => this.setState({ passwordInput: e.target.value })}
          type='password'
        />
      ];
      switchText = 'enter token';
      buttonClass = 'classicalButton';
    }

    if (error) {
      returnError = error;
    }

    return <div className='tokenModal'>
      <div className='tokenModalInner'>
        <Typography component='text'>
          You need a token to see the contents of this page
        </Typography>
        {returnError ? <div className='errors'>{returnError}</div> : null}
        <div className='enterCredentials'>
          {returnArray}
          <div
            key={buttonClass}
            className={`button ${buttonClass}`}
            onClick={() =>
              this.setState({
                loading: true
              }, () => this.authenticate())
            }
          >
            Submit
          </div>
        </div>
        <div
          className='switchMethod'
          onClick={
            () => this.setState({
              token: !token,
              passwordInput: '',
              userNameInput: '',
              tokenInput: '',
              error: false
          })}
        >
          {switchText}
        </div>
        <Typography component='text'>
          <MailToLink
            subject='Token'
            mailto='hello@datamodelers.de'
            body='Could you send me a token?'
          >
            or request a token
          </MailToLink>
        </Typography>
      </div>
    </div>;
  }
}

export default TokenModal;
