import { Component } from 'react';
import PropTypes from 'prop-types';

import './serviceTable.scss';

import tableTexts from './serviceTableTexts';
import { validateEmail } from '../../Utils/validators';

import Spacer from '../../Atoms/Spacer/spacer';
import Notifications from '../Notifications/notifications';
import Typography from '../../Atoms/Typography/typography';
import RequestServiceModal from '../../Molecules/RequestServiceModal/requestServiceModal';
import Icon from '../../Atoms/Icon/icon';

export default class ServiceTable extends Component {
  state = {
    unlockRequestModal: false,
    requestModalUnlocked: false,
    selectedServices: {},
    eMail: '',
    errors: [],
    isValidMail: false
  }

  validateInputAndUnlockModal() {
    const {
      isValidMail,
      selectedServices
    } = this.state;
    const errors = [];

    if(!isValidMail) {
      errors.push(
        {
          level: 'info',
          message_type: 'banner',
          message: 'E-mail address not valid'
        }
      );
    }

    if(Object.keys(selectedServices).length === 0) {
      errors.push({
        level: 'warn',
        message_type: 'banner',
        message: 'No services selected'
      });
    }

    this.setState({
      errors: errors,
      unlockRequestModal: errors.length === 0 && Object.keys(selectedServices).lenght !== 0
    });
  }

  requestPricingButton(id) {
    const {
      selectedServices
    } = this.state;
    const classNames = [
      'toggleRequestedService',
      'withPadding__10'
    ];
    let icon = <Icon icon='check' color='grey' width='20px' height='20px'/>;
    let returnText = 'Select';
    
    if(selectedServices[id]) {
      icon = <Icon icon='check' color='greenyellow' width='20px' height='20px'/>;
      returnText = 'Selected';
    }

    return <td
      onClick={() => this.requestPricingToggle(id)}
      className={classNames.join(' ')}
    >
      <div
        className='pricingButton'
      >
        {icon}
        <span>
          {returnText}
        </span>
      </div>
    </td>;
  }

  requestPricingToggle(id) {
    const {
      selectedServices
    } = this.state;

    let prePricingObject = selectedServices;
    let filteredReturnPricingObject = {};
    let active = true;

    if(prePricingObject[id]) {
      active = !selectedServices[id].active;
    }

    prePricingObject[id] = {
      id: id,
      active: active
    }

    Object.keys(prePricingObject).forEach(pricingObjectKey => {
      if(prePricingObject[pricingObjectKey].active) {
        filteredReturnPricingObject[pricingObjectKey] = {
          id: pricingObjectKey,
          active: prePricingObject[pricingObjectKey].active
        }
      }
    });

    this.setState({
      selectedServices: filteredReturnPricingObject,
      requestModalUnlocked: false
    }, () => this.validateInputAndUnlockModal());
  }

  checkEMailInput(input) {
    const validatedEmail = validateEmail(input);

    this.setState({
      eMail: input,
      isValidMail: validatedEmail,
      requestModalUnlocked: false
    }, () => this.validateInputAndUnlockModal());
  }

  render() {
    const {
      eMail,
      errors,
      unlockRequestModal,
      requestModalUnlocked,
      selectedServices
    } = this.state;

    const tableKeys = {
      services: {
        textAlign: 'right'
      },
      service_descriptions: {
        textAlign: 'left',
        responsiveCollapsedColumn: true
      },
      units: {
        textAlign: 'left'
      },
      request_pricings: {
        textAlign: 'center'
      } 
    };

    const returnTableBodyArray = [];
    const returnTableHeadArray = [];

    Object.keys(this.props.services).forEach(serviceKey => {
      const service = this.props.services[serviceKey];
      const collectUnits = [];

      service.unit_ids.map(unit => (
        collectUnits.push(unit.unit_names)
      ));

      returnTableBodyArray.push(
        <tr key={service.id}>
          <td
            className='withPadding__10'
            data-label={tableTexts('de', 'services')}
          >
            <Typography
              component='description'
              textAlign='right'
            >
              {service.services}
            </Typography>
          </td>
          <td
            className='withPadding__10 responsive__collapsedColumn'
            data-label={tableTexts('de', 'service_descriptions')}
          >
            <Typography
              component='description'
              textAlign='left'
            >
              {service.service_descriptions}
            </Typography>
          </td>
          <td
            className='withPadding__10'
            data-label={tableTexts('de', 'units')}
          >
            <Typography
              component='description'
              textAlign='left'
            >
              {collectUnits.join(', ')}
            </Typography>
          </td>
          {this.requestPricingButton(service.id)}
        </tr>
      );
    });

    Object.keys(tableKeys).forEach(tableKey => {
      const classNames = [
        'withPadding__10'
      ];

      if(tableKeys[tableKey].responsiveCollapsedColumn) {
        classNames.push('responsive__collapsedColumn');
      }

      returnTableHeadArray.push(
        <th className={classNames.join(' ')} key={tableKey}>
          <Typography
            component='tableheader'
            textAlign={tableKeys[tableKey].textAlign}
          >
            {tableTexts('de', tableKey)}
          </Typography>
        </th>
      )
    });

    return (
      <div
      className='serviceTableContainer'
      >

        {!requestModalUnlocked ?
          <Typography
            component='caption'
            textAlign='center'
          >
            Select some services, enter your e-mail address and submit your request.<br/>We will come back to you with an offer
          </Typography>
        : null}

        {!requestModalUnlocked ?
          <Spacer/>
        : null}

        {!requestModalUnlocked ?
          <table
            key='serviceTable'
            className='serviceTable'
          >
            <thead>
              <tr>
                {returnTableHeadArray}
              </tr>
            </thead>
            <tbody>
              {returnTableBodyArray}
            </tbody>
          </table>
        : null}

        {!requestModalUnlocked ?
          <input
            placeholder='Enter your e-mail address'
            defaultValue={eMail}
            onChange={(e) => this.checkEMailInput(e.target.value)}
          />
        : null}
        <div className='clear'/>

        {errors ? <Notifications notifications={errors}/> : null}

        {!requestModalUnlocked ?
          <div
            className={`submitButton ${!unlockRequestModal ? 'locked': 'unlocked'}`}
            onClick={() => unlockRequestModal ? this.setState({requestModalUnlocked: !requestModalUnlocked}) : null}
          >
            {!unlockRequestModal ? 'Select some services and enter your e-mail address' : 'Request services'}
          </div>
        : null}

        {requestModalUnlocked ? 
          <RequestServiceModal
            selectedServices={selectedServices}
            eMail={eMail}
            services={this.props.services}
          />
        : null}

      </div>
    );
  }
}

ServiceTable.propTypes = {
  services: PropTypes.array.isRequired
}
