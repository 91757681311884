import PropTypes from 'prop-types';

import './headerImage.scss';

export default function HeaderImage(props) {
  console.log('HELLO', props.positions.innerWindowWidth)
  console.log('HELLO', props.positions.innerWindowHeight)
  console.log('HELLO', props.positions.mousePositionX)
  console.log('HELLO', props.positions.mousePositionY)

  return <div
    key={'headerImage'}
    className='headerImage'
    style={{
      backgroundImage: `url(${props.image})`,
      backgroundPosition: '50% 50%'
    }}
  />;
}

HeaderImage.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};
