import PropTypes from 'prop-types';

export default function Icon(props) {
  return icon(props);
}

function icon(props) {
  const style = {
    stroke: props.color ? props.color : '#131313',
    strokeWidth: '20px',
    fill: 'none',
    strokeLinecap: 'round'
  };

  let width = '50px';
  if (props.width) {
    width = props.width;
  }

  let height = '50px';
  if (props.height) {
    height = props.height;
  }

  switch (props.icon) {
    case 'check':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
          width={width}
          height={height}
        >
          <line
            style={style}
            x1="131.1"
            y1="276.1"
            x2="181.84"
            y2="326.83"
          />
          <line
            style={style}
            x1="368.9"
            y1="188.6"
            x2="230.66"
            y2="326.83"
          />
          <circle
            style={style}
            cx="250"
            cy="250"
            r="235"
          />
        </svg>
      );
    case 'remove':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
          width={width}
          height={height}
        >
          <path
            d="M160.78,128a24.38,24.38,0,0,1,2,1.8A23.47,23.47,0,0,1,129.6,163c-.48-.48-.93-1-1.37-1.49A35.46,35.46,0,0,0,160.78,128m-7.13-10.12a29.47,29.47,0,0,1-35.75,36.78,29.47,29.47,0,1,0,35.75-36.78Z"
          />
          <line
            style={style}
            x1="174.37"
            y1="174.54"
            x2="225.1"
            y2="225.27"
          />
          <path
            d="M250,239.5a10.84,10.84,0,1,1-10.84,10.84A10.85,10.85,0,0,1,250,239.5m0-6a16.84,16.84,0,1,0,16.84,16.84A16.84,16.84,0,0,0,250,233.5Z"
          />
          <path
            d="M338.37,128.72a35.58,35.58,0,0,0,10.25,22.22,35.22,35.22,0,0,0,23.23,10.34,24.38,24.38,0,0,1-1.8,2,23.47,23.47,0,0,1-33.19-33.19c.48-.48,1-.94,1.51-1.38m6.82-10.31A29.47,29.47,0,1,0,382,154.15a29.84,29.84,0,0,1-8.27,1.18,29.46,29.46,0,0,1-28.51-36.92Z"
          />
          <line
            style={style}
            x1="325.29"
            y1="174.87"
            x2="274.55"
            y2="225.6"
          />
          <path
            d="M146.41,330.22A23.48,23.48,0,0,1,163,370.29c-.49.48-1,.94-1.51,1.38A35.42,35.42,0,0,0,128,339.11a22.85,22.85,0,0,1,1.8-2,23.27,23.27,0,0,1,16.59-6.88m0-6a29.45,29.45,0,0,0-28.51,22,29.84,29.84,0,0,1,8.27-1.18A29.46,29.46,0,0,1,154.68,382a29.47,29.47,0,0,0-8.27-57.76Z"
          />
          <line
            style={style}
            x1="174.58"
            y1="325.52"
            x2="225.32"
            y2="274.79"
          />
          <path
            d="M353.81,330.17A23.3,23.3,0,0,1,370.4,337c.48.48.94,1,1.37,1.49A35.47,35.47,0,0,0,339.23,372a23,23,0,0,1-2-1.8,23.47,23.47,0,0,1,16.6-40.06m0-6a29.47,29.47,0,0,0-7.45,58,29.47,29.47,0,0,1,35.74-36.78,29.47,29.47,0,0,0-28.29-21.2Z"
          />
          <line
            style={style}
            x1="325.64"
            y1="325.46"
            x2="274.9"
            y2="274.73"
          />
          <circle
            style={style}
            cx="250"
            cy="250"
            r="235"
          />
        </svg>
      );
    default:
      return 'NO ICON FOUND';
  }
}

Icon.propTypes = {
  icon: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};
