import {
  Routes as Switch,
  BrowserRouter,
  Route
} from 'react-router-dom';

import Home from './Components/Pages/Home/home';
import Blog from './Components/Pages/Blog/blog';
import BlogPost from './Components/Pages/BlogPost/blogPost';
import Services from './Components/Pages/Services/services';
import Imprint from './Components/Pages/Imprint/imprint';
import NotFound from './Components/Pages/NotFound/notFound';
import DataModelers from './Components/Templates/DataModelers/main';
import ServiceOffer from './Components/Pages/ServiceOffer/serviceOffer';
import ServiceOffers from './Components/Pages/ServiceOffers/serviceOffers';

export default function Routes() {
  return <BrowserRouter>
    <Switch>
      <Route path='/' element={<DataModelers><Home/></DataModelers>}/>
      <Route exact path='/services' element={<DataModelers><Services/></DataModelers>}/>
      <Route exact path='/service-offers' element={<DataModelers><ServiceOffers/></DataModelers>}/>
      <Route exact path='/service-offers/:id' element={<DataModelers><ServiceOffer/></DataModelers>}/>
      <Route exact path='/blog' element={<DataModelers><Blog/></DataModelers>}/>
      <Route exact path='/blog/:post_id' element={<DataModelers><BlogPost/></DataModelers>}/>
      <Route exact path='/imprint' element={<DataModelers><Imprint/></DataModelers>}/>
      <Route path='*' element={<DataModelers><NotFound/></DataModelers>}/>
    </Switch>
  </BrowserRouter>;
}
