import './notFound.scss';

import Typography from '../../Atoms/Typography/typography';

function NotFound() {
  return (
    <div className='notFoundPage'>
      <Typography component='h1'>
        404
      </Typography>
    </div>
  );
}

export default NotFound;
