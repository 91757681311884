import PropTypes from 'prop-types';

import './blogCategoryPill.scss';

export default function BlogCategoryPill(props) {
  return (
    <div
      key={`blogCategoryPill`}
      className={`blogCategoryPill`}
    >
      {props.name}
    </div>
  );
}

BlogCategoryPill.propTypes = {
  name: PropTypes.string.isRequired
};
