import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './logoWithText.scss';

import Logo from '../../Atoms/Logo/logo';
import RotatingImage from '../../Molecules/RotatingImage/rotatingImage';

export default function LogoWithText(props) {
  const {
    REACT_APP_PAGE_NAME
  } = process.env;

  return <Link
    to={'/'}
    key={'logoHomeClick'}
    className='logoLink'
  >
    {
      props.rotation ? <RotatingImage
        width='60px'
        height='60px'
        speed={20}
      >
        <Logo
          width={props.width}
          height={props.height}
        />
      </RotatingImage> 
    :  <Logo
        width={props.width}
        height={props.height}
      />
    }
    <span className='logoText'>
      {REACT_APP_PAGE_NAME}
    </span>
  </Link>;
}

LogoWithText.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string
};
