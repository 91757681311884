import './navigationBar.scss';

import NavigationLink  from '../../Atoms/Navigation/Link/link';

const NAVIGATION_ELEMENTS = [
  { name: 'Home', shortName: 'home', path: '/' },
  { name: 'Services', shortName: 'services', path: '/services' },
  // { name: 'Service Offers', shortName: 'service-offers', path: '/service-offers' },
  { name: 'Blog', shortName: 'blog', path: '/blog' },
  { name: 'Imprint', shortName: 'imprint', path: '/imprint' },
];

function NavigationBar(props) {
  const returnButtons = [];

  NAVIGATION_ELEMENTS.map(element => returnButtons.push(
    <NavigationLink
      path={element.path}
      shortName={element.shortName}
      key={element.shortName}
      name={element.name}
    />
  ));

  return (
    <div className='navigationBar'>
      {returnButtons}
    </div>
  );
}

export default NavigationBar;
