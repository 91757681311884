import './header.scss';

import Typography from '../../Atoms/Typography/typography';
import LogoWithText from '../../Molecules/LogoWithText/logoWithText';

export default function Template(props) {
  return (
    <div className='header'>
      <LogoWithText width='60px' height='60px' rotation={props.rotation}/>
      {
        props.subtitle ? <Typography component='subtitle'>
          {props.subtitle}
        </Typography> : null
      }
    </div>);
}